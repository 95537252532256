import { useRef, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/atoms/form_elements/Button";
import FormGroup from "../../components/atoms/form_elements/FromGroup";
import Container from "../../components/atoms/layouts/container/Container";
import InlineLoader from "../../components/atoms/loader/InlineLoader";
import MainHeadingText from "../../components/atoms/text_labels/MainHeadingText";
import Api from "../../lib/Api";

export default function BookConsultation() {

  const [loading, setLoading] = useState(false);

  const { current: formData } = useRef({
    name: "",
    email: "",
    contact: "",
    state: "Mizoram",
    course: "Medical",
    appointment_date: "",
    submission_date: ""
  });


  const setData = (fieldName, data) => {
    formData[fieldName] = data;
  }

  const onSubmitForm = (e) => {
    e?.preventDefault();
    setLoading(true);

    const url = "https://script.google.com/macros/s/AKfycbwD4uUluUglp0AqGW44HLto6ENUOqB_3tDEAoUH2hysHECoGky7R8_u-YvnuPUwMaqt/exec";
    const url2 = "/book-appointment"

    const payload = { ...formData, submission_date: new Date().toJSON() }

    const saveToSheetsPromise = Api.post(url, payload);
    const saveToLambda = Api.post(url2, payload);

    Promise.all([saveToSheetsPromise, saveToLambda]).then(([sheetsResJson, lambdaResJson]) => {
      if (sheetsResJson.success === true) {
        toast.success(sheetsResJson.message);
      }
      if (lambdaResJson.success === true) {
        toast.success(lambdaResJson.message);
      }
    }).catch(err => {
      console.log("Error", err);
      toast.error("Sorry, we're facing a problem right now.");
    })
      .finally(() => {
        setLoading(false);
      })
  }

  return (
    <div>
      <div className="grey-bg">
        <Container className="long-para-content py-100">
          <MainHeadingText>Book a<br></br> free <br />Consultation</MainHeadingText>
        </Container>
      </div>

      <Container className={`long-para-content pt-0 pb-100`}>


        <form className="mt-50" onSubmit={onSubmitForm}>
          <FormGroup>
            <label className="d-block">Name</label>
            <input placeholder="John Doe" required onChange={e => {
              setData("name", e.target.value);
            }} />
          </FormGroup>
          <FormGroup>
            <label className="d-block">Email</label>
            <input type="email" placeholder="john.doe@domain.com" required onChange={e => {
              setData("email", e.target.value);
            }} />
          </FormGroup>
          <FormGroup>
            <label className="d-block">Phone</label>
            <input type="tel" name="phone" placeholder="10 digit number" required pattern="[0-9]{10}" onChange={e => {
              setData("contact", e.target.value);
            }} />
          </FormGroup>
          <FormGroup>
            <label className="d-block">State</label>
            <select defaultValue={formData.state} required onChange={e => {
              setData("state", e.target.value);
            }}>
              <option disabled>Select</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Sikkim">Assam</option>
              <option value="Tripura">Tripura</option>
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Bihar">Bihar</option>
              <option value="Chhattisgarh">Chhattisgarh</option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh	</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Madhya Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="West Bengal">West Bengal</option>
              <option value="West Bengal">Other</option>
            </select>
          </FormGroup>
          <FormGroup>
            <label className="d-block">Which Stream are you interested in?</label>
            <select defaultValue={formData.course} required onChange={e => {
              setData("course", e.target.value);
            }}>
              <option disabled>Select</option>
              <option value="Medical">Medical</option>
              <option value="Management">Management</option>
              <option value="Arts & Commerce">Arts & Commerce</option>
              <option value="Science">Science</option>
              <option value="Technology">Technology</option>
              <option value="Hospitality">Hospitality</option>
              <option value="Hospitality">Other</option>
            </select>
          </FormGroup>
          <FormGroup>
            <label>Appointment Date</label>
            <input type="date" onChange={e => {
              setData("appointment_date", e.target.value);
            }} />
          </FormGroup>

          <FormGroup className="mt-30 d-flex align-items-center">
            <Button type="submit" themeType="theme1" className="w-px-200 d-flex align-items-center justify-content-center" disabled={loading}>
              <span className="mr-10">
                Book Now
              </span>
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </Button>
            {loading ? <InlineLoader className="ml-20" text="Scheduling your appointment..." /> : ''}
          </FormGroup>

        </form>

      </Container>
    </div>
  )
}