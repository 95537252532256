import Container from "../../../atoms/layouts/container/Container"
import MainHeadingText from "../../../atoms/text_labels/MainHeadingText"
import Paragraph from "../../../atoms/text_labels/Paragraph"
import style from "./OurCompany.module.scss"

export default function OurCompany() {
  return (
    <section className="section-about-us">
      <Container className="about-us-grid d-flex justify-content-space-between flex-wrap-wrap">

        <div className="text-section d-flex align-items-center pr-50 pr-mobile-0 flex-basis-60  flex-basis-mobile-100">
          <div className="mb-mobile-30">
            <MainHeadingText className="section-header-1 header-text dark-brown-font mb-50">About Our <br />Company</MainHeadingText>
            <Paragraph>Discover ConnectEdu, your gateway to turning educational dreams into reality. As the guiding force behind
              your academic success, we specialize in providing personalized consultancy services that extend beyond
              traditional boundaries. From aiding in college selection to managing travel logistics, accommodation, and
              offering unwavering support throughout your academic journey.
              <br /><br />
              ConnectEdu embraces a modern approach with a
              steadfast commitment to excellence. Our mission is to redefine education consultancy, tailoring solutions to
              ensure each student embarks on a successful and enriching academic experience.
            </Paragraph>
          </div>
        </div>

        <div className={`flex-basis-40 flex-basis-mobile-100 ${style.ImageGallery}`}>
          {/* <img className=" border-radius-px-10" src={`${process.env.PUBLIC_URL}/images/about-2.jpg`} /> */}
          {/* <img className=" border-radius-px-20" src={`${process.env.PUBLIC_URL}/images/about-1.jpg`} /> */}
          <img className=" border-radius-px-20 d-block" title="Image by pikisuperstar on Freepik" src={`${process.env.PUBLIC_URL}/images/about_us.svg`} />
          {/* <span className="text-center d-block" style={{fontSize: "10px", lineHeight: "10px"}}>
            <a href="https://www.freepik.com/free-vector/brainstorming-concept-landing-page_5757169.htm#query=about%20us&position=1&from_view=keyword&track=ais&uuid=5541ef0f-fd4e-4e27-aaf2-00accd567ab9">Image by pikisuperstar</a> on Freepik
          </span> */}
          {/* <figcaption className="text-center font-style-italic">College students in bangalore</figcaption> */}
          {/* <img className=" border-radius-px-10" src={`${process.env.PUBLIC_URL}/images/about-3.jpg`} /> */}
          {/* <img className=" border-radius-px-20" src={`${process.env.PUBLIC_URL}/images/about-4.jpg`} /> */}
        </div>
      </Container>
    </section>
  )
}