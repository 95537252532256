import { Route, Routes } from "react-router-dom";
import Home from "../features/home/Home";
import About from "../features/about/About";
import TermsConditions from "../features/terms/TermsConditions";
import Policy from "../features/policy/Policy";
import ContactUs from "../features/contact_us/ContactUs";
import BookConsultation from "../features/book_consultation/BookConsultation";


export default function AppRouter(){
  return (
    <Routes>
      <Route
        exact
        strict
        path="/"
        element={<Home/>}
      />
      <Route
        exact
        strict
        path="/home"
        element={<Home/>}
      />
      <Route
        exact
        strict
        path="/about-us"
        element={<About/>}
      />
      <Route
        exact
        strict
        path="/terms"
        element={<TermsConditions/>}
      />
      <Route
        exact
        strict
        path="/privacy-policy"
        element={<Policy/>}/>
      <Route
        exact
        strict
        path="/contact-us"
        element={<ContactUs/>}/>
      <Route
        exact
        strict
        path="/book-consultation"
        element={<BookConsultation/>}
      />
    </Routes>
  )
}