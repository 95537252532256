import Container from "../../components/atoms/layouts/container/Container";
import MainHeadingText from "../../components/atoms/text_labels/MainHeadingText";


export default function Policy() {
  return (
    <Container className="py-100 long-para-content">
      <MainHeadingText className="section-header-1 dark-brown-font mb-100">Privacy Policy</MainHeadingText>


      <p className="mb-30 long-para-text">
        At ConnectEdu, we are committed to safeguarding the privacy of our website visitors and clients. This Privacy Policy outlines how we collect, use, and protect your personal information. By accessing or using our website and services, you agree to the terms outlined in this policy.
      </p>

      <h4 className="mb-15 long-para-heading">
        Information We Collect:
      </h4>
      <h4 className="mb-15 long-para-heading">
        Personal Information:
      </h4>
      <p className="mb-30 long-para-text">
        We may collect personal information, such as names, contact details, and academic background, to provide tailored education consultancy services. This information is voluntarily provided by users during consultations and interactions.
      </p>
      <h4 className="mb-15 long-para-heading">
        Usage Data:
      </h4>
      <p className="mb-30 long-para-text">
        We gather non-personal information about how users interact with our website, including pages visited, time spent on the site, and other analytical data. This information helps us improve our website and services.
      </p>

      <hr className="mb-60 mt-60" />

      <h4 className="mb-15 long-para-heading">
        How We Use Your Information:
      </h4>
      <h4 className="mb-15 long-para-heading">
        Education Consultancy:
      </h4>
      <p className="mb-30 long-para-text">
        Personal information is used to provide academic counseling, travel logistics, accommodation assistance, scholarship guidance and other associated assistance.
      </p>
      <h4 className="mb-15 long-para-heading">
        Communication:
      </h4>
      <p className="mb-30 long-para-text">
        We may use your contact information to communicate updates, respond to inquiries, and provide information related to our services.
      </p>
      <h4 className="mb-15 long-para-heading">
        Website Improvement:
      </h4>
      <p className="mb-30 long-para-text">
        Usage data is analyzed to enhance our website's functionality, content, and user experience.
      </p>

      <hr className="mb-60 mt-60" />

      <h4 className="mb-15 long-para-heading">
        Data Security:
      </h4>
      <h4 className="mb-15 long-para-heading">
        Protection Measures:
      </h4>
      <p className="mb-30 long-para-text">
        ConnectEdu employs industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.
      </p>
      <h4 className="mb-15 long-para-heading">
        Data Retention:
      </h4>
      <p className="mb-30 long-para-text">
        We retain personal information for the duration necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law.
      </p>

      <hr className="mb-60 mt-60" />

      <h4 className="mb-15 long-para-heading">
        Sharing Your Information:
      </h4>
      <h4 className="mb-15 long-para-heading">
        Third-Party Partners:
      </h4>
      <p className="mb-30 long-para-text">
        ConnectEdu may share your information with trusted third-party partners to facilitate admission process, travel logistics, accommodation, or other services as part of the package.
      </p>

      <h4 className="mb-15 long-para-heading">
        Legal Compliance:
      </h4>
      <p className="mb-30 long-para-text">
        We may disclose your information when required by law or in response to valid legal requests.
      </p>


      <hr className="mb-60 mt-60" />
      

      <h4 className="mb-15 long-para-heading">
        Your Privacy Choices:
      </h4>
      <h4 className="mb-15 long-para-heading">
        Opt-Out:
      </h4>
      <p className="mb-30 long-para-text">
        You have the option to opt-out of receiving non-essential communications from ConnectEdu.
      </p>
      <h4 className="mb-15 long-para-heading">
        Access and Correction:
      </h4>
      <p className="mb-30 long-para-text">
        You can request access to your personal information and correct any inaccuracies.
      </p>

      <h4 className="mb-15 long-para-heading">
        Policy Updates:
      </h4>
      <p className="mb-30 long-para-text">
        ConnectEdu reserves the right to update this Privacy Policy. We encourage you to review this policy periodically for any changes.
      </p>


      <hr className="mb-60 mt-60" />

      <h4 className="mb-15 long-para-heading">
        Contact Us:
      </h4>

      <p className="mb-30 long-para-text">
      For any questions or concerns regarding this Privacy Policy, please contact us at __________________.
      </p>

      <p className="mb-30 long-para-text">
        Thank you for entrusting ConnectEdu with your educational journey.
      </p>

    </Container>
  )
}