import CardHeaderText from "../../../atoms/text_labels/CardHeaderText";
import ExpandingBlock from "../../expanding_block/ExpandingBlock";


export default function CardTypeB({ className = '', shortDescription, longDescription, name, iconName }) {
  return (
    <div className={`${className} d-flex`}>
      <div className="flex-basis-20 d-flex pt-20 justify-content-center">
        <ion-icon name={iconName} style={{fontSize: "60px"}}></ion-icon>
      </div>
      <div className="flex-basis-80 px-20">
        <CardHeaderText>{name}</CardHeaderText>
        <p>{shortDescription}</p>
        {/* <p>{longDescription}</p> */}
        {longDescription && <ExpandingBlock triggerHeader="Learn more.." bodyText={longDescription}/>}
      </div>
    </div>
  )
}