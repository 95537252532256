import { useContext } from "react"
import { GlobalContext } from "../../../../contexts/GlobalContext"
import MainHeadingText from "../../../atoms/text_labels/MainHeadingText"
import CardTypeA from "../../cards/card_type_a/CardTypeA"
import DisplayGrid from "../../../atoms/layouts/grid/DisplayGrid";

export default function Courses() {

  const courses = useContext(GlobalContext)?.globalState.courses || [];

  return (
    <section className="affiliate grey-bg">
      <div className="container px-mobile-20">
        <MainHeadingText className="mb-80 dark-brown-font text-center">Courses</MainHeadingText>

        <DisplayGrid col={3} colMobile={1} colTablet={2} gap={30}>
          {courses.map(courseDomain =>
            <CardTypeA key={courseDomain.id} imgUrl={courseDomain.image_url} title={courseDomain.course_domain} listItems={courseDomain.courses} />
          )}
        </DisplayGrid>

      </div>
    </section>
  )
}